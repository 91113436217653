import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCalendar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import _ = require('lodash');
import { Moment } from 'moment';
import moment = require('moment');
import { start } from 'repl';
import { DateMaskDirective } from 'src/app/directives/date-mask.directive';
import { DateTimeMaskDirective } from 'src/app/directives/date-time-mask.directive';
import { dateDifference, disableDay, disableWeekEnd, isValidDate, stringToMoment } from 'src/app/helpers/date.helper';
import { ListDates } from 'src/app/models/list-dates.model';
import { WorkCalendar } from '../../models/work-calendar.model';
import '../../services/method-extensions';
import { DOCUMENT } from '@angular/common'; 
import { element } from 'protractor';
import { forEach } from 'lodash';

enum DeleteRangeCode {
  AUTO = 0,
  ALWAYS_START = 1,
  ALWAYS_END = 2,
}

export interface InputLabelsStrings {
  startDate?: string,
  endDate?: string,
}

@Component({
  selector: 'app-date-range-input',
  templateUrl: './date-range-input.component.html',
  styleUrls: ['./date-range-input.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    DateMaskDirective,
  ],
})
export class DateRangeInputComponent implements OnInit {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  @Input() selectedDatesDisable: boolean = false;
  @Input() selectRange: boolean = true;
  @Input() dateTime: boolean = false;
  @Input() selectFullDays: boolean = false;
  @Input() startViewDateEnd: boolean = false;
  @Input() setMinMaxDates: boolean = true;
  startDateRange: Date = null;

  startTime: Moment;
  finishTime: Moment;

  formatDates: string = '';

  @Output() selectedDatesChange: EventEmitter<ListDates> = new EventEmitter();

  formGroupStartDateTime: FormGroup = new FormGroup({
    startDate: new FormControl('', Validators.required),
    startTime: new FormControl(null, Validators.required), 
  });

  formGroupFinishDateTime: FormGroup = new FormGroup({
    finishDate: new FormControl('', Validators.required),
    finishTime: new FormControl(null, Validators.required),
  });

  minDate;
  maxDate;
  selectedDatesPreloaded = false;

  
  @Input() inputLabels: InputLabelsStrings = null;

  @ViewChild('startDate', { static: false }) startDate: ElementRef;
  //@ViewChild('startTimeInput', { static: false }) startTimeInput: ElementRef;
  @ViewChild('finishDate', { static: false }) finishDate: ElementRef;
  // @ViewChild('finishTimeInput', { static: false }) finishTimeInput: ElementRef;
  

  @Input() workCalendar: WorkCalendar;
  @Input() disabledDays = [];
  @Input() selectedDates: ListDates;
  @Input() showRangeSelector = true;
  @Input() isITLeave = false;
  @Input() showClearButton = true;
  @ViewChild('calendar', { static: false }) calendar: MatCalendar<Moment>;

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor( 
    private translate: TranslateService,
    
    ) { }

  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */

  ngOnInit() {
    if (this.selectedDates) {
      this.selectedDatesPreloaded = true;
      this.setFormInputDates();
    } else {
      if (this.dateTime && this.selectFullDays) {
        this.formatDates = 'YYYY-MM-DDTHH:mm.ss';
      } else {
        this.formatDates = 'YYYY-MM-DD';
      }

      this.selectedDates = new ListDates(this.formatDates);
    }

    if (this.workCalendar) {
      this.setMinAndMaxDateOnCalendar();
    }


  }

  ngAfterContentInit(): void {
    this.setInputTimes();
    
  }


  /**
   * This function will fire before ngOnInit() and every time parentData is updated from its parent component.
   * @param changes @see SimpleChanges
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.workCalendar && changes.workCalendar.currentValue) {
      this.setMinAndMaxDateOnCalendar();
    }
  }

  /**
   * Asigna la minDate y maxDate del calendario.
   */
  setMinAndMaxDateOnCalendar() {
    if (this.setMinMaxDates) {
      /**
       * Si se precargan las fechas, quiere decir que se está editando una solicitud, por tanto bloqueamos todo el
       * calendario excepto para los días marcados por la solicitud.
       */
      if (this.selectedDatesPreloaded) {
        if (this.selectedDatesDisable) {
          this.formGroupFinishDateTime.disable();
          this.formGroupStartDateTime.disable();
        }
        const datesToDisable = [];
        const currDate = moment(this.selectedDates.first());
        const lastDate = moment(this.selectedDates.last());

        while (currDate.add(1, 'days').diff(lastDate) < 0) {
          datesToDisable.push(currDate.clone().toDate());
        }

        const indexesToNotDisable = [];
        this.selectedDates.dates.forEach((date, iDate) => {
          // Si la fecha iterada no es la primera ni la última.
          if (iDate !== 0 && iDate !== this.selectedDates.dates.length) {
            // Comprobamos que esta fecha exista en el array de fechasToDisable
            datesToDisable.forEach((dateToDisable, iDateToDisable) => {
              // Si existe, quiere decir que no debemos desactivar esta fecha en el calendario.
              if (moment(dateToDisable).diff(moment(date)) == 0) {
                indexesToNotDisable.push(iDateToDisable);
              }
            });
          }
        });

        // Reordenamos el array de mayor a menor índice para prevenir errores al hacer el splice.
        indexesToNotDisable.sort(function (a, b) {
          return b - a;
        });

        // Si el tamaño del array de índices a no desactivar es distinto que el tamañó de fechas a desactivar procedemos.
        if (indexesToNotDisable.length !== datesToDisable.length) {
          indexesToNotDisable.forEach(indexNotToDisable => {
            datesToDisable.splice(indexNotToDisable, 1);
          });

          datesToDisable.forEach(dateToDisable => {
            this.disabledDays.push({ date: dateToDisable });
            disableDay(moment(dateToDisable), this.disabledDays);
          });
        }
        this.minDate = this.selectedDates.first();

        // Si no es una ausencia por baja IT bloqueamos la selcción de más días
        if (!this.isITLeave) {
          this.maxDate = this.selectedDates.last();
        }

        /**
         * En caso contrario, bloqueamos el calendario para que únicamente se puedan seleccionar los días del año
         * asignado al workCalendar.
         */
      } else if (this.workCalendar) {
        this.minDate = new Date(Number(this.workCalendar.validYear), 0, 1);
        this.maxDate = new Date(Number(this.workCalendar.validYear), 11, 31);
      }
    }
  }

  /* ################################################################################################################## */
  /* ## DATES RANGES
  /* ################################################################################################################## */
  /**
   * Alterna el estado de selección individual de fechas o de selección de un rango.
   */
  range() {
    this.selectRange = !this.selectRange;
  }

  /**
   * Alterna el estado de selección individual de fechas o de selección de un rango.
   */
  fullDays() {
    this.selectFullDays = !this.selectFullDays;
    
//todo revisar                                                             
    // if (this.startTimeInput && this.startTimeInput.nativeElement) {
    //   this.startTimeInput.nativeElement.querySelectorAll('ngx-mat-timepicker .ng-star-inserted')
    //     .forEach( ( btn ) => {
    //         btn.setAttribute('tabindex', '-1');
    //     });
    // }
  }

  /**
   * Limpia las fechas seleccionadas.
   *
   * @param rangeClear rango de fechas que se eliminarán de la colección
   * @param reset indica si se debe resetear el formulario
   */
  clear(rangeClear: Date[] = this.selectedDates.dates, reset: boolean = true) {
    const deleteRange = this.selectedDates.getCloneDates(rangeClear);

    for (let item of deleteRange) {
      if (!this.selectedDates.empty()) {
        this.selectedDates.delete(item);
      }
    }

    this.calendar.updateTodaysDate();
    this.setStartDateRange(null);

    if (reset) {
      this.formGroupStartDateTime.reset();
      this.formGroupFinishDateTime.reset();
      

      if (this.dateTime && !this.selectFullDays) {
        this.setInputTimes();
      }
    }
  }

  /**
   * Devuelve un rango de fechas que se deben eliminar para actualizar la colección y
   * determina si ese rango se debe tomar desde el principio o desde el final de la colección.
   *
   * @param date fecha a tomar como referencia
   * @param startRange determina por donde debe iniciar el rango
   * @returns colección de fechas a eliminar
   */
  getDeleteRange(date: Date, startRange: DeleteRangeCode = DeleteRangeCode.AUTO) {
    let result: Date[] = [];

    let deleteBegin: Date[] = null;
    let deleteEnd: Date[] = null;

    if (
      date >= this.selectedDates.first() &&
      date <= this.selectedDates.last()
    ) {
      deleteBegin = this.selectedDates.getRangeDates(null, date);
      deleteEnd = this.selectedDates.getRangeDates(date, null);

      switch (startRange) {
        case DeleteRangeCode.ALWAYS_START:
          result = deleteBegin;
          break;
        case DeleteRangeCode.ALWAYS_END:
          result = deleteEnd;
          break;
        case DeleteRangeCode.AUTO:
        default:
          if (deleteBegin.length < deleteEnd.length) {
            result = deleteBegin;
          } else {
            result = deleteEnd;
          }
          break;
      }
    }

    return result;
  }

  /* ################################################################################################################## */
  /* ## DATES
  /* ################################################################################################################## */
  /**
   * Determina los estilos css que debe tener una fecha seleccionada
   *
   * @param event fecha con la que operar
   * @returns clase css a devolver
   */
  isSelected = (event: any) => {
    const date = new Date(event);
    return this.selectedDates.find(date) != -1 ? 'selected' : null;
  };

  /**
   * Selecciona o deselecciona la fecha pasada como parámetro
   *
   * @param event fecha seleccionada
   *
   * https://stackblitz.com/edit/angular-32fsyf-gggf3u?file=src%2Fapp%2Fdatepicker-overview-example.ts
   */
  select(event: any, unselectDay: boolean = true) {
    let date = new Date(event.format('YYYY-MM-DD'));
    const index = this.selectedDates.find(date);

    if (index >= 0) {
      date = this.selectedDates.get(index);
    }

    if (this.selectRange && !this.selectedDates.empty()) {
      if (date < this.selectedDates.first()) {
        this.setStartDateRange(date); // Se establece el inicio de selección a la nueva fecha
        date = this.selectedDates.first(); // Se establece la última fecha a introducir la
        // primera de la colección (no se repetirá porque la colección no admite duplicados)

        this.selectedDates.add(new Date(event)); // Se introduce la nueva fecha en la colección
      } else if (date > this.selectedDates.last() && this.startDateRange == null) {
        this.setStartDateRange(this.selectedDates.last()); // Se establece el inicio de selección a la última fecha de la colección
      
      }
    }

    if (index < 0 || !unselectDay) {
      if (this.selectRange && this.startDateRange != null) {
        if (isValidDate(this.startDateRange)) {
          // Modo selección de rango
          let initDate = new Date(this.startDateRange);
          let diff = Math.trunc(dateDifference(this.startDateRange, date));

          for (let i = 1; i <= diff; i++) {
            let tempDate = new Date(initDate);
            tempDate.setDate(initDate.getDate() + i);

            if (this.getDisabledDays(moment(tempDate))) {
              this.selectedDates.add(tempDate);
            }
          }
        }

        this.setStartDateRange(null);
      } else {
        // Modo selección individual
        this.setStartDateRange(this.selectRange ? date : null);
      }

      if (this.getDisabledDays(moment(date))) {
        this.selectedDates.add(date);

        if (this.dateTime && !this.selectFullDays && this.selectedDates.length() == 1) {
          this.selectedDates.add(date, true); // Se necesita duplicar para establecer la hora de finalización
        }
      }
    } else if (unselectDay) {
      // Si no se han precargado fechas y sólamente queda 1
      if (this.selectedDatesPreloaded && this.selectedDates.length() == 1) {
      } else {
        if (this.dateTime && !this.selectFullDays && this.selectedDates.length() == 2) {
          this.selectedDates.delete(date, true, false);

          // Si la selección no queda vacía hay que establecer la fecha de inicio y finalización
          if (!this.selectedDates.empty()) {
            this.selectedDates.add(this.selectedDates.first(), true);
          }
        }

        if (this.selectRange) {
          let deleteRange = this.getDeleteRange(date);
          this.clear(deleteRange, false);
        } else {
          this.selectedDates.delete(date);
        }

        this.setStartDateRange(null);
      }
    }

    this.calendar.updateTodaysDate(); // Se actualiza la vista del calendario

    if (this.selectedDates.length() > 2) {
      this.selectedDates.deleteDuplicates(); // Si hay más de 2 fechas nos aseguramos de que no existan
      // fechas seleccionadas que coincidan con el mismo día
    }

    if (this.dateTime && !this.selectFullDays) {
      this.setRangeTimes(); // Se asignan las horas a la nueva selección
      this.setInputTimes(); // Se actualizan los selectores de hora de inicio y final
    }

    this.setFormInputDates(); // Se actualizan los inputs de inicio y final

    this.selectedDatesChange.emit(this.selectedDates);
  }

  /**
   * Asigna un valor válido a la fecha de inicio de selección de rango.
   *
   * @param date fecha a asignar
   */
  setStartDateRange(date) {
    this.startDateRange = this.setDate(date);
  }

  /**
   * Establece la hora de fecha a: 0:0:0:0
   *
   * @param date fecha a asignar
   */
  setDate(date) {
    let tempDate = null;

    if (date != null) {
      tempDate = new Date(date);

      tempDate.setHours(0);
      tempDate.setMinutes(0);
      tempDate.setSeconds(0);
      tempDate.setMilliseconds(0);
    }

    return tempDate;
  }

  /**
   * Comprueba los días que se deben deshabilitar del calendario
   *
   * @param d fecha en formato objeto momentjs
   * @returns
   */
  getDisabledDays = (d: any | null): boolean => {
    let result = true;

    if (d != null) {
      if ((d.day() == 0 || d.day() == 6)) {
        result = disableWeekEnd(d, this.workCalendar) && // Comprueba si es fin de semana y en el calendario está marcado como no laborable
          disableDay(d, this.disabledDays); // Comprueba si es un festivo
      } else {
        result = disableDay(d, this.disabledDays); // Comprueba si es un festivo
      }
    }

    return result;
  }

  /* ################################################################################################################## */
  /* ## INPUT LABELS
  /* ################################################################################################################## */

  /**
   * Devuelve la etiqueta que se tiene que mostrar en el input desde el que se llama
   *
   * @param label etiqueta que se quiere seleccionar (START_DATE | END_DATE)
   * @returns string
   */
  getInputLabel(label: string) {
    let result = "";

    if (this.inputLabels != null) {
      if (this.inputLabels.startDate != null && this.inputLabels.startDate != "" && label == "START_DATE") {
        result = this.inputLabels.startDate;
      } else if (this.inputLabels.endDate != null && this.inputLabels.endDate != "" && label == "END_DATE") {
        result = this.inputLabels.endDate;
      }
    }

    if (result == "") {
      this.translate.get(`DATE_TIME_PICKER.LABEL.${label}`).subscribe((a: string) => {
        result = a;
      });
    }

    return result;
  }

  /**
   * Devuelve la etiqueta que se tiene que se tiene que mostrar en la fecha de inicio.
   * @returns string
   */
   getStartDateLabel() {
    return this.getInputLabel("START_DATE");
  }

  /**
   * Devuelve la etiqueta que se tiene que se tiene que mostrar en la fecha de fin.
   * @returns string
   */
  getEndDateLabel() {
    return this.getInputLabel("END_DATE");
  }

  /* ################################################################################################################## */
  /* ## INPUTS
  /* ################################################################################################################## */

  /**
   * Cambia los inputs de inicio y fin de la solicitud.
   */
  setFormInputDates() {
    let startDate = "";
    let finishDate = "";
    let timesOK = true;

    let format = "YYYY-MM-DD";

    if (this.dateTime && !this.selectFullDays) {
      format = "YYYY-MM-DD HH:mm";

      if (this.startTime.diff(this.finishTime) > 0) {
        timesOK = false;
      }
    }

    

    if (!this.selectedDates.empty() && timesOK) {
      let start = this.selectedDates.first();
      let end = this.selectedDates.last();

      if (this.selectedDates.first().getTime() < this.minDate.getTime()) {
        start = this.minDate.getTime();
      }

      if (this.selectedDates.last().getTime() > this.maxDate.getTime()) {
        end = this.maxDate.getTime();
      }
      
      let temp = this.selectedDates.getRangeDates(start, end);
      this.selectedDates.setDates(temp);

      startDate = this.selectedDates.getDateString(this.selectedDates.first(), format);
      finishDate = this.selectedDates.getDateString(this.selectedDates.last(), format);
    } else {

     this.formGroupStartDateTime.get("startDate").markAsTouched();
      this.formGroupFinishDateTime.get("finishDate").markAsTouched();
    }

    this.formGroupStartDateTime.patchValue( { startDate } );
    this.formGroupFinishDateTime.patchValue( { finishDate } );
  }

/**
 * Asigna los días seleccionados si se introducen manualmente las fechas de inicio y de fin de la solicitud.
 *
 * @param event evento de cambio de estado
 * @param finishDate determina si el input es el de fecha de fin
 */
 changeInput(event: any, finishDate: boolean) {
  // TODO: Solucionar error en consola: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'aria-describedby: null'. Current value: 'aria-describedby: mat-error-9'.

  let date: Moment = stringToMoment(event.target.value, this.dateTime);
  let deleteRangeCode: DeleteRangeCode = DeleteRangeCode.ALWAYS_START;

  // Se guarda el modo de selección
  let selectRange = this.selectRange;

  // Se establece el modo de selección como rango
  this.selectRange = true;

  if (this.dateTime && !this.selectFullDays) {
    if (finishDate) {
      this.setFinishTime(date);
    } else {
      this.setStartTime(date);
    }
  }

  if (finishDate) {
    deleteRangeCode = DeleteRangeCode.ALWAYS_END;
  }

  this.clear(this.getDeleteRange(date.toDate(), deleteRangeCode), false);

  // Se introduce el rango seleccionado
  this.select(date, false);

  // Se recupera el modo de selección previo
  this.selectRange = selectRange;
}

/* ################################################################################################################## */
/* ## TIME
/* ################################################################################################################## */

/**
 * Selecciona los valores de los inputs de hora de inicio y fin.
 */
setInputTimes() {
  this.setStartTime();
  this.setFinishTime();
}

/**
 * Actualiza las horas de incio o fin según los eventos de la UI
 *
 * @param origin indica que hora se debe actualizar
 * @param event nueva hora
 */
onTimeChanged(origin: string, event: Moment) {
  this[`set${origin}`](event);
  this.setRangeTimes();
  this.setFormInputDates();
}

/**
 * Actualiza la hora de inicio de la solicitud
 *
 * @param date nueva hora
 */
setStartTime(date: Moment = null) {
  let result = moment(new Date());

  if (date != null) {
    result = date;
  } else {
    result.minutes(0);
    result.seconds(0);

    if (!this.selectedDates.empty()) {
      result = moment(this.selectedDates.first());
    }
  }

  if (this.selectFullDays) {
    result.hours(0);
    result.minutes(0);
  }

  this.startTime = result;
}

/**
 * Actualiza la hora de finalización de la solicitud
 *
 * @param date nueva hora
 */
setFinishTime(date: Moment = null) {
  let result = moment(new Date());

  if (date != null) {
    result = date;
  } else {
    result.minutes(0);
    result.seconds(0);

    if (!this.selectedDates.empty()) {
      result = moment(this.selectedDates.last());
    }
  }

  if (this.selectFullDays) {
    result.hours(0);
    result.minutes(0);
  }

  this.finishTime = result;
}

/**
 * Establece la hora de cada fecha de una colección a: 0:0:0:0
 *
 * @param rangeDate colección de fechas con las que operar
 */
resetRangeTimes(rangeDate = this.selectedDates.dates) {
  for (let i in rangeDate) {
    rangeDate[i] = this.setDate(rangeDate[i]);
  }
}

/**
 * Establece la hora de inicio de la primera fecha de una colección a la que está selecciona
 * en la UI y la hora de finalizacíon a la última fecha.
 *
 * @param rangeDate colección de fechas con las que operar
 */
setRangeTimes(rangeDate = this.selectedDates.dates) {
  if (!this.selectedDates.empty(rangeDate)) {
    this.resetRangeTimes(rangeDate); // Se resetean las horas de la colección

    let first = this.selectedDates.first(rangeDate);
    let last = this.selectedDates.last(rangeDate);

    first.setHours(this.startTime.hours());
    first.setMinutes(this.startTime.minutes());

    last.setHours(this.finishTime.hours());
    last.setMinutes(this.finishTime.minutes());
  }
}

/* ################################################################################################################## */
/* ## UTILS
/* ################################################################################################################## */
/**
 * Selecciona el mes en el que se debe mostrar en el calendario.
 *
 * @returns Moment
 */
getStartViewDate(): Moment {
  let startView: Moment = null;

  if (this.selectedDates) {
    // Si hay fechas seleccionadas significa que estamos en modo edición y hay que situarse en el mes del primer día del rango
    let pos = 0;

    if (this.startViewDateEnd && this.selectedDates.dates.length > 0) {
      pos = this.selectedDates.dates.length - 1;
    }

    startView = moment(this.selectedDates.dates[pos]);
  }

  return startView;
}
}
