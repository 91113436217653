import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ResponseNotificationService, BaseListComponent, SecurityStateManagementService, AccountService, Hotkeys, Role } from 'inzo-portalempleado';
import { AppPermissions } from 'src/app/models/app-permission.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { HolidayRequest } from 'src/app/models/holiday-request.model';
import { HolidayRequestService } from 'src/app/services/holiday-request.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material';
import { EmployeeManagementService } from 'src/app/services/employee-management.service';
import { ToastrService } from 'ngx-toastr';
import { HolidayRequestStateService } from 'src/app/services/holiday-request-state.service';
import { HolidayManagementRejectDialogComponent } from '../holiday-management-reject-dialog/holiday-management-reject-dialog.component';
import { HolidayRequestState } from 'src/app/models/holiday-request-state.model';
import { HolidayRequestStateCode } from 'src/app/models/holiday-request-state-code-enum.model';
import { HolidayRequestManagementCalendarComponent } from '../holiday-request-management-calendar/holiday-request-management-calendar.component';
import { EmployeeEvent, HolidayRequestEvent, HolidayRequestListEvent } from 'src/app/interfaces/events.interface';
import { Employee } from '../../../models/employee.model';
import { FiltersApi } from 'src/app/models/filters-api.model';
import { HolidayRequestGroups, HolidayRequestGroupsItems, HolidayRequestStateLabel } from '../../../interfaces/holidays.interface';
import { FiltersApiIds } from 'src/app/models/filters-api-ids.model';
import { HolidayRequestChangesService } from 'src/app/services/holiday-request-changes.service';
import { EmployeeSituationCode } from 'src/app/models/employee-situation-enum.model';
import { PermissionsService } from 'src/app/modules/pe-common/services/permissions.service';
import { IUserRolesPermissions } from 'src/app/modules/pe-common/interfaces/permissions.interface';
import { EmployeeSelectionService } from 'src/app/modules/employee/services/employee-selection.service';

@Component({
  selector: 'app-holiday-request-management-groups',
  templateUrl: './holiday-request-management-groups.component.html',
  styleUrls: [
    './holiday-request-management-groups.component.css',
    '../../maintenance.components.css',
    '../../maintenance-list.component.css',
  ],
})
export class HolidayRequestManagementGroupsComponent extends BaseListComponent<HolidayRequestGroupsItems> implements OnInit, OnDestroy {
  /* ################################################################################################################## */
  /* ## ATRRIBUTES
  /* ################################################################################################################## */
  employeeView : boolean = false;
  holidayRequestStatesTabs: HolidayRequestStateLabel[] = [];

  employees: Employee[] = [];
  holidayRequestsGroups: HolidayRequestGroupsItems[] = [];
  holidayRequests: HolidayRequest[] = [];
  selectedEmployees: Employee[] = [];
  currentEmployee: Employee;
  holidayRequestStateCode = HolidayRequestStateCode;
  holidayRequestStates: HolidayRequestState[] = [];

  tabStates: string[] = [];
  excludeStates: HolidayRequestStateCode[] = [];
  visibleStatesCalendar: HolidayRequestStateCode[] = [];

  currentYear: number;

  subscriptions: Subscription[] = [];
  searching = true;
  filter = '';
  mediaSize: string;
  watcher: Subscription;
  employeeAssociatedToUserLoggedId = null;
  loadDataAlreadyLaunched = false;
  @ViewChild('inputsearch', { static: false }) inputSearch: ElementRef;
  @ViewChild('calendar', { static: false }) calendar: HolidayRequestManagementCalendarComponent;

  filtersApiEmployee: FiltersApi = new FiltersApi();
  filtersApiHolidays: FiltersApi = new FiltersApi();
  filtersRequestEmployeeIds: FiltersApiIds = new FiltersApiIds();

  // Permissions
  canAdd = false;
  canDelete = false;

  canDirectAssignment = false;
  directAssignment : boolean = false;

  roles: Role[];

  userRolesPermissions: IUserRolesPermissions = {
    isAdmin: false,
    isRRHH: false,
    isRA: false,
    isRP: false,
    isEmployee: false,
  }

  /* ################################################################################################################## */
  /* ## CONSTRUCTOR
  /* ################################################################################################################## */
  constructor(
    protected holidayRequestService: HolidayRequestService,
    public holidayRequestStateService: HolidayRequestStateService,
    protected holidayRequestChangesService: HolidayRequestChangesService,
    public route: ActivatedRoute,
    public router: Router,
    public SSMService: SecurityStateManagementService,
    protected RNService: ResponseNotificationService,
    public accountService: AccountService,
    public employeeManagementService: EmployeeManagementService,
    public hotkeys: Hotkeys,
    protected spinner: NgxSpinnerService,
    protected translate: TranslateService,
    protected dialog: MatDialog,
    protected toastrService: ToastrService,
    protected employeeSelectionService: EmployeeSelectionService,
    protected permissionsService: PermissionsService,
  ) {
    super(accountService, SSMService, router, hotkeys);
    this.baseRoute = '/managements/holiday_request_management';

    this.viewPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
      AppPermissions.ROLE_DATA.RP,
    ];
    this.createPermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
    ];
    this.deletePermission = [
      AppPermissions.ROLE_DATA.RRHH,
      AppPermissions.ROLE_DATA.RA,
    ];
    if (this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RRHH) ||
      this.accountService.userHasPermission(AppPermissions.ROLE_DATA.RA)) {
    }
    this.onBuild = () => {
    };

    // Filtros para búsqueda de solicitudes
    this.currentYear = new Date().getFullYear();
    this.setFilterDate();

    this.filtersApiHolidays.add(
      {
        field: "related",
        value: "true"
      }
    );

    // Filtros para búsqueda de Empleados
    this.filtersApiEmployee.add(
      {
        field: "related",
        value: "false"
      }
    );

    this.filtersApiEmployee.add(
      {
        field: "situation",
        value: EmployeeSituationCode[EmployeeSituationCode.ACTIVE]
      }
    );

    this.loadData = this.customLoadData;

    this.onInit = this.init;
  }

  //#region ANGULAR METHODS
  /* ################################################################################################################## */
  /* ## ANGULAR METHODS
  /* ################################################################################################################## */
  ngOnInit() {
    // userRolesPermissions
    this.subscriptions.push(
      this.permissionsService.userRolesPermissions.subscribe(data => {
        this.userRolesPermissions = data;

        if (this.userRolesPermissions && this.userRolesPermissions.isRRHH && !this.employeeView) {
          this.directAssignment = true;
          this.holidayRequestChangesService.onChangeCheck(this.directAssignment);

          this.canAdd = true;
          this.canDelete = true;

          this.canDirectAssignment = true;
          this.holidayRequestChangesService.onChangecanDirectAssignment(this.canDirectAssignment);
        }

        this.onInit();
      })
    );

    // roles
    this.subscriptions.push(
      this.permissionsService.roles.subscribe(data => {
        this.roles = data;
      })
    );
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.holidayRequestChangesService.onChangeCheck(false);
    if (this.userPermissionsLoadedSubscription) {
      this.userPermissionsLoadedSubscription.unsubscribe();
    }
  }
  //#endregion

  /* ################################################################################################################## */
  /* ## DATA MANIPULATION
  /* ################################################################################################################## */
  init() {
    this.getHolidaysRequestState();

    this.subscriptions.push(
      this.holidayRequestChangesService.check.subscribe(checked => {
        this.directAssignment = checked;
        this.setRequestStates();
        this.loadData();
      })
    );

    this.subscriptions.push(
      this.holidayRequestService.holidayRequestGroupObservable.subscribe(holidays => {
        this.loadHolidayRequests(holidays);
      })
    );
  }

  customLoadData() {
    this.spinner.show();
    const userId = this.accountService.currentUser.id;

    this.subscriptions.push(
      this.route.paramMap.subscribe(params => {
        this.subscriptions.push(
          this.employeeManagementService.getEmployeeByUserId(userId, false).subscribe(employeeAssociatedToUserLogged => {
            this.employeeAssociatedToUserLoggedId = employeeAssociatedToUserLogged.employeeId;

            if (!this.loadDataAlreadyLaunched) {
              // Filtros para búsqueda de solicitudes
              this.filtersApiHolidays.add(
                {
                  field: "Management",
                  value: "true"
                }
              );


              this.loadDataAlreadyLaunched = true;
            }
            // this.holidayRequestService.getAllHolidayRequestGroupBy(this.filtersApiHolidays.getStrinFilterApi(), this.filtersHolidaysEmployeeIds.filters).subscribe(holidayRequestsGroups => {
            //   const filteredItems = this.assignHolidaysRequestStates(holidayRequestsGroups.items);

            //   this.holidayRequestService.updateHolidayRequestsGroup(filteredItems);
            //   this.loadHolidayRequests(filteredItems);
            // });
            this.spinner.hide();
          })
        )
      })
    );
  }

  refresh() {
    // Al refrescar forzamos a cargar los datos de nuevo asignando la variable `loadDataAlreadyLaunched` a false
    this.loadDataAlreadyLaunched = false;
    this.loadData();
  }

  /**
   * Recupera todas las solicitudes de vacaciones y muestra un toast. Se debe emplear sólo si se ha logrado actualizar
   * bien la solicitud pasada.
   *
   * @param holidayRequest - La solicitud de vacaciones a actualizada
   * @param newState - Nuevo estado de la solicitud de vacaciones
   */
  getAllHolidayRequestGroupBy(holidayRequest: HolidayRequest, newState: string) {
    // this.holidayRequestService.getAllHolidayRequestGroupBy(this.filtersApiHolidays.getStrinFilterApi(), this.filtersHolidaysEmployeeIds.filters).subscribe(holidayRequestsGroups => {
    //   const filteredItems = this.assignHolidaysRequestStates(holidayRequestsGroups.items);

    //   this.holidayRequestService.updateHolidayRequestsGroup(filteredItems);
    //   this.loadHolidayRequests(filteredItems);
    //   this.refreshSelectedEmployees(filteredItems);

    //   this.successToastRequest(newState, holidayRequest.reason);
    //   this.spinner.hide();
    // });
  }


  // TODO: refacotrizar para simplificar
  loadHolidayRequests(items) {
    // Se vacían los arrays
    // this.holidayRequestsGroups = new Array();
    // this.holidayRequests = new Array();

    // // Se prepara el array nominativo
    // for (let stateName of this.tabStates) {
    //   this.holidayRequestsGroups[stateName] = [];
    // }

    // // Se rellena el array con los distintos items
    // for (let item of items) {
    //   let temp = {
    //     ALL: new Array()
    //   };

    //   // Se recorren las HolidayRequest de cada item y se clasifican
    //   for (let holidayRequest of item) {
    //     if (!this.excludeStates.includes(holidayRequest.holidaysRequestState.stateCode)) {
    //       let indexName = HolidayRequestStateCode[holidayRequest.holidaysRequestState.stateCode];
    //       if (indexName.includes("PENDING")) {
    //         indexName = "PENDING";
    //       }

    //       if (temp.hasOwnProperty(indexName)) {
    //         temp[indexName].push(holidayRequest);
    //       } else {
    //         temp[indexName] = [holidayRequest];
    //       }

    //       this.holidayRequests.push(holidayRequest);
    //       temp["ALL"].push(holidayRequest);
    //     }
    //   }

    //   // Se añaden las HolidayRequest ya clasificadas a la lista agrupadas por Employee
    //   for (let element in this.holidayRequestsGroups) {
    //     if (Object.keys(temp).includes(element)) {
    //       this.holidayRequestsGroups[element].push(temp[element]);
    //     }
    //   }
    // }
  }

  getHolidaysRequestState() {
    this.holidayRequestStateService.getAllHolidaysRequestState().subscribe(holidayRequestStates => {
      this.holidayRequestStates = holidayRequestStates.items;
    }, error => {
      this.RNService.showError(error);
    });
  }

  refreshSelectedEmployees(items: HolidayRequestGroups[]) {
    for (let item of items) {
      let employee = item[0].employee;

      if (
        this.currentEmployee != undefined &&
        this.currentEmployee != null &&
        employee.employeeId == this.currentEmployee.employeeId
      ) {
        Object.assign(this.currentEmployee, employee);
      }

      for (let selectedEmployee of this.selectedEmployees) {
        if (employee.employeeId == selectedEmployee.employeeId) {
          Object.assign(selectedEmployee, employee);
        }
      }

      for (let selectedEmployee of this.employees) {
        if (employee.employeeId == selectedEmployee.employeeId) {
          Object.assign(selectedEmployee, employee);
        }
      }
    }
  }

  /**
   * Recoge un evento del componente de listado de Employees y ejecuta la acción correspondiente a ese evento.
   *
   * @param employeeEvent evento recogido
   */
  selectEmployee(employeeEvent: EmployeeEvent) {
    if (employeeEvent.eventType == "SELECT") {
      if (employeeEvent.employee == null) {
        // Filtros para búsqueda de solicitudes
        this.filtersApiHolidays.delete("employeeId");
        // Filtros para búsqueda de Empleados
        this.filtersApiEmployee.delete("employeeId");

        this.selectedEmployees = [];
        this.filtersRequestEmployeeIds = new FiltersApiIds();
        this.currentEmployee = null;
      } else {
        if (!this.selectedEmployees.includes(employeeEvent.employee)) {
          this.selectedEmployees.push(employeeEvent.employee);
          this.currentEmployee = employeeEvent.employee;

          // Filtros para búsqueda de solicitudes
          this.filtersRequestEmployeeIds.add(employeeEvent.employee.employeeId);
        } else {
          const index = this.selectedEmployees.indexOf(employeeEvent.employee);

          if (index > -1) {
            this.selectedEmployees.splice(index, 1);
          }

          // Filtros para búsqueda de solicitudes
          this.filtersRequestEmployeeIds.delete(employeeEvent.employee.employeeId);

          this.currentEmployee = (this.selectedEmployees[0])
            ? this.selectedEmployees[0]
            : null;
        }
      }

      this.employeeSelectionService.onChangeCurrentEmployee(this.currentEmployee);
      this.employeeSelectionService.onChangeSelectionEmployees(this.selectedEmployees);

      this.loadData();
    }
  }

  /**
   * Actualiza el filtro de año
   *
   * @param year nuevo año
   */
  currentYearEvent(year: number) {
    this.currentYear = year;
    this.setFilterDate();
    this.loadData();
  }

  /* ################################################################################################################## */
  /* ## CRUD
  /* ################################################################################################################## */
  /**
   * Recoge un evento del componente de listado de solicitudes y ejecuta la acción correspondiente a ese evento.
   *
   * @param holidayRequestEvent evento recogido
   */
  actionHoliday(holidayRequestEvent: HolidayRequestEvent) {
    switch (holidayRequestEvent.eventType) {
      case "REJECTED":
        this.rejectHolidayRequest(holidayRequestEvent.holidayRequest, holidayRequestEvent.eventType);
        break;
      default:
        this.updateHolidayRequest(holidayRequestEvent.holidayRequest, holidayRequestEvent.eventType);
        break;
    }
  }

  /**
   * Pasa la solicitud de vacaciones recibida como param y el nuevo estado y se abre un
   * dialog en el que se deberá escribir la razón por la cual se rechaza la solicitud.
   *
   * @param holidayRequest - La solicitud de vacaciones a actualizar
   * @param newState - Estado al que debe camibar la solicitud de vacaciones
   */
  async rejectHolidayRequest(holidayRequest: HolidayRequest, newState: string) {
    if (!this.directAssignment) {
      const rejectHolidayRequestDialog = this.dialog.open(HolidayManagementRejectDialogComponent, { width: '550px' });

      rejectHolidayRequestDialog.afterClosed().subscribe((reason: string) => {
        if (reason) {
          this.updateHolidayRequest(holidayRequest, newState, reason);
        }
      });
    } else {
      this.updateHolidayRequest(holidayRequest, newState);
    }
  }

  /**
   * Pasa la solicitud de vacaciones recibida como param y el nuevo estado.
   * @param holidayRequest - La solicitud de vacaciones a actualizar
   * @param newState - Estado al que debe camibar la solicitud de vacaciones
   */
  async updateHolidayRequest(holidayRequest: HolidayRequest, newState: string, reasonReject: string = "") {
    let holidayRequestNewState = this.holidayRequestStates.find(element => element.stateCode == HolidayRequestStateCode[newState]);
    this.spinner.show();

    if (this.directAssignment) {
      this.holidayRequestService.changeStateHolidaysRequestToEmployee(holidayRequest.holidaysRequestId, holidayRequestNewState.holidaysRequestStateId, reasonReject).subscribe(response => {
        this.getAllHolidayRequestGroupBy(holidayRequest, newState);

        this.holidayRequestChangesService.refreshData(true);
      }, error => {
        this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((lang: string) => {
          this.toastrService.error(error.error, lang, { timeOut: 3000 });
        });
      }, () => {
        this.spinner.hide();
      });
    } else {
      this.holidayRequestService.changeStateHolidayRequest(holidayRequest.holidaysRequestId, holidayRequestNewState.holidaysRequestStateId, reasonReject).subscribe(response => {
        this.getAllHolidayRequestGroupBy(holidayRequest, newState);

        this.holidayRequestChangesService.refreshData(true);
      }, error => {
        this.translate.get('MESSAGES.ERROR.UPDATE_CONFLICT').subscribe((lang: string) => {
          this.toastrService.error(error.error, lang, { timeOut: 3000 });
        });
      }, () => {
        this.spinner.hide();
      });
    }
  }

  /* ################################################################################################################## */
  /* ## UTILS METHODS
  /* ################################################################################################################## */
  /**
   * Actualiza el estado de las solicitudes
   *
   * @param holidayRequestList nuevo año
   */
  holidayRequestListEvent(holidayRequestList: HolidayRequestListEvent) {
    this.holidayRequests = holidayRequestList.holidayRequestList;
  }

  assignHolidaysRequestStates(items) {
    for (let item of items) {
      for (let holidaysRequest of item) {
        // Se recupera el estado
        holidaysRequest.holidaysRequestState = this.holidayRequestStates.find(element => element.holidaysRequestStateId == holidaysRequest.holidaysRequestStateId);
      }
    }

    return items;
  }

  /**
   * Selecciona los filtros para establecer el rango de fechas de las vacaciones
   */
  setFilterDate() {
    this.filtersApiHolidays.add(
      {
        field: "startDate",
        value: `${this.currentYear}-01-01T00:00:00.000Z`
      },
      true
    );

    this.filtersApiHolidays.add(
      {
        field: "finishDate",
        value: `${this.currentYear}-12-31T23:59:59.999Z`
      },
      true
    );
  }

  /**
   * Crea un mensaje de cambio de estado correcto de la solicitud
   *
   * @param state nuevo estado de la solicitud
   * @param reasonReject razón del rechazo
   */
  successToastRequest(state: string, reasonReject: string = "") {
    let msg = "UPDATE";

    switch (state) {
      case "APPROVED":
        msg = "APPROVED";
        break;
      case "ANNULLED":
        msg = "ANNULLED";
        break;
      case "CANCELED":
        msg = "CANCEL";
        break;
      case "PENDING_APROVAL":
      case "PENDING_ANNULLED":
        msg = "SEND";
        break;
      case "REJECTED":
        msg = "REJECTED";
        break;
    }

    this.translate.get('HOLIDAY_REQUEST_MAINTENANCE.NAME').subscribe((componentTranslated: string) => {
      let toastTitle = '';
      let toastBody = '';
      this.translate.get(`MESSAGES.SUCCESS.${msg}`).subscribe((a: string) => { toastTitle = a });
      this.translate.get(`MESSAGES.SUCCESS.${msg}_LONG`, { type: componentTranslated, code: reasonReject }).subscribe((a: string) => { toastBody = a });
      this.toastrService.success(toastBody, toastTitle, { timeOut: 3000 });
    });
  }

  /**
   * Selecciona los estados que se deben manejar y los que se deben mostrar.
   */
  setRequestStates() {
    if(this.directAssignment) {
      this.tabStates = [
        "PENDING",
        "APPROVED",
        "REJECTED",
        "REGISTERED",
        "ANNULLED",
        // "CANCELED",
        "ALL",
      ];

      this.excludeStates = [
        HolidayRequestStateCode.CANCELED,
      ];

      this.visibleStatesCalendar = [
        // HolidayRequestStateCode.ANNULLED,
        HolidayRequestStateCode.APPROVED,
        // HolidayRequestStateCode.CANCELED,
        HolidayRequestStateCode.PENDING_APROVAL,
        HolidayRequestStateCode.PENDING_CHANGE_APROVAL,
        HolidayRequestStateCode.PENDING_ANNULLED,
        // HolidayRequestStateCode.REGISTERED,
        // HolidayRequestStateCode.REJECTED,
      ];
    } else {
      this.tabStates = [
        "PENDING",
        "APPROVED",
        "REJECTED",
        "ANNULLED",
        "ALL",
      ];

      this.excludeStates = [
        HolidayRequestStateCode.REGISTERED,
        HolidayRequestStateCode.CANCELED,
      ];

      this.visibleStatesCalendar = [
        HolidayRequestStateCode.APPROVED,
        HolidayRequestStateCode.PENDING_APROVAL,
        HolidayRequestStateCode.PENDING_CHANGE_APROVAL,
        HolidayRequestStateCode.PENDING_ANNULLED,
      ];
    }

    this.setFilterHolidaysRequestsStates();
  }

  /**
   * Asigna el filtro para obtener sólo las solicitudes con los estados que se van a manejar.
   */
  setFilterHolidaysRequestsStates() {
    this.filtersApiHolidays.addMultipleValue(
      "holidaysRequestStateId",
      this.getRequestStatesId(this.excludeStates, true),
      true
    );
  }

  /**
   * Devuelve los Ids de la base de datos de la lista de códigos de estado pasada como parámetro.
   *
   * @param holidaysRequestStateCodeList lista de códigos a procesar.
   * @param reverse si es true en lugar de procesar los estados de la lista pasada se procesan los
   * los estados que no coincidan con la lista.
   * @returns una lista de Ids de estados
   */
  getRequestStatesId(holidaysRequestStateCodeList: HolidayRequestStateCode[], reverse: boolean = false) {
    let result: string[] = [];

    let holidaysRequestStateTemp: HolidayRequestState[] = [];

    if (reverse) {
      holidaysRequestStateTemp = this.holidayRequestStates.filter(element => !holidaysRequestStateCodeList.includes(element.stateCode));
    } else {
      holidaysRequestStateTemp = this.holidayRequestStates.filter(element => holidaysRequestStateCodeList.includes(element.stateCode));
    }

    for (let item of holidaysRequestStateTemp) {
      result.push(item.holidaysRequestStateId);
    }

    return result;
  }

  shownEmployeesChanged(shownEmployees: any[]) {
    this.employees = shownEmployees;
  }
}
