import { WorkCalendar } from '../models/work-calendar.model';
import { Moment } from 'moment';
import moment = require("moment");
import { LocaleSettings } from 'inzo-calendar-lib/lib/Interface/LocaleSettings';
import { TranslateService } from '@ngx-translate/core';
import { ResultCheckType } from '../interfaces/checks-helper.interface';
import { checkType } from './check-types.helper';

//#region UTILS
/* ******************************************************************************************************************** */
/* ** UTILS
/* ******************************************************************************************************************** */
/**
 * Recupera los nombres de los días de la semana y de los meses según el idioma seleccionado en la aplicación.
 *
 * @param translate Sevicio de traducción
 * @returns Un objeto con la interfaz LocaleSettings
 */
export function getLocaleNames(translate: TranslateService): LocaleSettings {
  let result: LocaleSettings = {
    dayNamesMin: [],
    monthNames: []
  };

  translate.get('CALENDAR.DAYS_OF_WEEK').subscribe((days: string[]) => {
    result.dayNamesMin = days;
  });

  translate.get('CALENDAR.MONTHS').subscribe((months: string[]) => {
    result.monthNames = months;
  });

  return result;
}

/**
  * Comprueba si la fecha pasada está en la lista y devuleve un boolean para desactivar el
  * día en el calendario.
  *
  * @param d fecha en formato objeto momentjs
  * @param disabledDays array de fechas deshabilitadas en formato inzo-calendar: [ { date: Date }, ... ]
  * @returns false si encuentra el día
  */
export function disableDay(d: any, disabledDays: any[]): boolean {
  let result = true;
  const monthDay = d.toDate().getDate();
  const month = d.toDate().getMonth();

  for (let item of disabledDays) {
    if (monthDay == item.date.getDate() && month == item.date.getMonth()) {
      result = false;
      break;
    }
  }

  return result;
}

/**
  * Comprueba si la fecha pasada es un los fin de semana y devuleve un boolean para desactivar el
  * día en el calendario.
  *
  * @param d fecha en formato objeto momentjs
  * @param workCalendar calendario laboral sobre el que hacer la comprobación
  * @returns false si es fin de semana
  */
export function disableWeekEnd(d: any, workCalendar: WorkCalendar): boolean {
  const day = (d).day();
  // Prevent Saturday and Sunday from being selected.
  let result = day !== 0 && day !== 6 || (day === 0 || day === 6) && !workCalendar.disableWeekends;

  return result;
}
//#endregion

//#region CREATE DATES
/* ******************************************************************************************************************** */
/* ** CREATE DATES
/* ******************************************************************************************************************** */
/**
 * Crea una fecha igual a la mínima de .NET = Mon Jan 01 0001 00:00:00 GMT-0014 (hora estándar de Europa central)
 *
 * @returns Date
 */
export function getMinDate(): Date {
  return new Date(-62135595917000);
}

/**
 * Crea una fecha igual a la mínima de .NET = Mon Jan 01 0001 00:00:00 GMT-0014 (hora estándar de Europa central)
 *
 * @returns Date
 */
export function getMinDateString(): string {
  return getDateTimeString(getMinDate());
}

/**
 * Crea un array de fechas entre las pasadas como parámetros
 *
 * @param {Date} startDate fecha de inicio del rango (incluida)
 * @param {Date} endDate fecha de final del rango (incluida)
 * @returns Date
 * @link https://stackoverflow.com/a/28011965
 */
export function getArrayDates(startDate: Date, endDate: Date): Date[] {
  let result : Date[] = new Array();

  let day = moment(startDate);
  let finishRange = moment(endDate);

  while (day <= finishRange) {
    result.push(day.toDate());
    day = day.clone().add(1, "d");
  }

  return result;
}

//#endregion

//#region CHECKS
/* ******************************************************************************************************************** */
/* ** CHECKS
/* ******************************************************************************************************************** */
/**
 * Devuelve la diferencia entre dos finishs
 *
 * @param {Date} startDate finish más antigua
 * @param {Date} finishDate finish más start (por defecto ahora)
 * @param {Char} interval de tiempo en el que se desea que devuelva la diferencia:
 *                          d -> días (por defecto)
 *                          h -> horas
 *                          m -> minutos
 *                          s -> segundos
 *                          * -> milisegundossegundos
 */
export function dateDifference(startDate: Date, finishDate: Date = new Date(), interval = "d"): number {
  let intervalTime = 1;
  let start = new Date(startDate);
  let finish = new Date(finishDate); // para asegurarse que finish es correcta
  let msDifference = finish.getTime() - start.getTime();

  switch (interval) {
    case "d":
      intervalTime *= 24;
    case "h":
      intervalTime *= 60;
    case "m":
      intervalTime *= 60;
    case "s":
      intervalTime *= 1000;
    case "*":
    default:
      break;
  }

  return msDifference / intervalTime;
}

/**
 * Compara dos fechas con la precisión que se define en el formato.
 *
 * @param date1 fecha 1
 * @param date2 fecha 2
 * @param precisionFormat precisión de la comparación
 * @returns integer con la representación estandar de comparación
 */
export function compare2Dates(date1: Date, date2: Date, precisionFormat = 'YYYY-MM-DD'): number {
  let result: number = -1;

  if (moment(date1, precisionFormat).diff(moment(date2, precisionFormat)) == 0) {
    result = 0;
  } else if (moment(date1, precisionFormat).diff(moment(date2, precisionFormat)) > 0) {
    result = 1;
  }

  return result;
}
/**
 * Comprueba si la fecha es válida para la apliación.
 *
 * @param date Fecha a comprobar
 * @returns boolean
 */
export function isValidDate(date: Date | string): boolean {
  let result = false;
  let checks: ResultCheckType = checkType(date);

  if (
    checks.isDate &&
    !isMinDate(date)
  ) {
    result = true;
  }

  return result;
}

/**
 * Comprueba si la fecha es igual a la mínima de .NET.
 *
 * @param date Fecha a comprobar
 * @returns boolean
 */
export function isMinDate(dateInput: Date | string): boolean {
  let result = false;
  let date: Date = new Date(dateInput);

  if (
    date.getFullYear() == 1 &&
    date.getMonth() == 0 &&
    date.getDate() == 1 ||
    date.toISOString() == "0001-01-01T00:00:00.000Z"
  ) {
    result = true;
  }

  return result;
}
//#endregion

//#region TRANSFORMS
/* ******************************************************************************************************************** */
/* ** TRANSFORMS
/* ******************************************************************************************************************** */
/**
  * Devuelve un string de una fecha y hora.
  *
  * @param dates Lista de fechas a convertir
  * @param format formato por defecto
  * @returns un string con una fecha y hora
  */
export function getArrayDateString(dates: Date[] | string[], format: string = 'YYYY-MM-DD'): string {
  let result = "[\r\n";

  for (let i = 0; i < dates.length; i++) {
    let date = dates[i];

    result += getDateString(date, format);

    if (i != dates.length - 1) {
      result += ","
    }

    result += "\r\n"
  }

  result += "]";

  return result;
}

/**
  * Devuelve un string de una fecha y hora.
  *
  * @param dates Lista de fechas a convertir
  * @param format formato por defecto
  * @returns un string con una fecha y hora
  */
export function getArrayDateTimeString(dates: Date[] | string[], format: string = 'YYYY-MM-DD HH:mm'): string {
  return getArrayDateString(dates, format);
}

/**
 * Convierte la fecha pasada en el formato deseado
 *
 * @param date fecha a convertir
 * @param format formato en el que se desea convertir la fecha
 * @returns string con una fecha, por defecto válida para convertir en un DateTime de C#
 */
export function transformDate(date: string, format = 'YYYY-MM-DD HH:mm'): string {
  // Conversión correcta para la API de fechas
  return moment(date).utc().format(format);
}

/**
  * Devuelve un string de una fecha en el formato especificado.
  *
  * @param date Fecha a convertir
  * @param format formato en el que se quiere sacar la fecha
  * @returns un string con una fecha en el formato especificado
  */
export function getDateString(date: Date | string, format: string = 'YYYY-MM-DD'): string {
  let result = "";

  if (isValidDate(date)) {
    result = moment(date).format(format);
  }

  return result;
}

/**
  * Devuelve un string de una fecha y hora.
  *
  * @param date Fecha a convertir
  * @param format formato por defecto
  * @returns un string con una fecha y hora
  */
export function getDateTimeString(date: Date | string, format: string = 'YYYY-MM-DD HH:mm'): string {
  return getDateString(date, format);
}

/**
  * Devuelve un string de una fecha y hora o un string vacío si la fechas es inválida.
  *
  * @param date Fecha a convertir
  * @param format formato por defecto
  * @returns un string con una fecha y hora
  */
export function getDateOrEmpty(date: any, format: string): string {
  let result = getDateTimeString(date, format);

  return (result != "Invalid date")
    ? result
    : "";
}

/**
 * Convierte un string de una fecha en un tipo Date
 *
 * @param date string con una fecha en formato DD/MM/YYYY HH:mm:ss a convertir
 * @param parseTime indica si se quiere retornar también la hora con minutos y segundos
 * @returns una fecha de tipo Date
 */
export function stringToDate(date: any, parseTime: boolean = false): Date {
  let result: Date = null;
  let checkDate: ResultCheckType = checkType(date);

  if (date) {
    if (checkDate.isDate) {
      result = new Date(ChangeDateFormat(date));
    } else {
      let value: string = date.replace(" ", "/").replaceAll(":", "/");
      let dateArrayInt = value.split("/").map((item) => Number.parseInt(item, 10));

      result = new Date(dateArrayInt[2], dateArrayInt[1] - 1, dateArrayInt[0]);

      if (parseTime && dateArrayInt.length > 3) {
        if (dateArrayInt.length >= 4) {
          result.setHours(dateArrayInt[3]);
        }
        if (dateArrayInt.length >= 5) {
          result.setMinutes(dateArrayInt[4]);
        }
        if (dateArrayInt.length >= 6) {
          result.setSeconds(dateArrayInt[5]);
        }
      }
    }
  }

  return result;
}

/**
 * Conversor de strigs de fechas de DD/MM/YYYY a YYYY-MM-DD
 *
 * @param oldDate string con formato DD/MM/YYYY
 * @returns string con formato YYYY-MM-DD (el que admite el constructor de Date)
 */
function ChangeDateFormat(oldDate: string): string{
   return oldDate.split("/").reverse().join("-");
}




/**
 * Convierte un string de una fecha con formato ISO 8601 (DD-MM-YYYYTHH:mm:ssZ) en un tipo Date
 *
 * @param date string con una fecha en formato ISO 8601 a convertir
 * @param parseTime indica si se quiere retornar también la hora con minutos y segundos
 * @returns una fecha de tipo Date
 */
export function stringISO8601ToDate(date: any, parseTime: boolean = false): Date {
  let value: string = date.replace(" ", "-").replaceAll(":", "-").replace("T", "-").replace("Z", "");
  let dateArrayInt = value.split("-").map((item) => Number.parseInt(item, 10));

  let result: Date | moment.Moment = new Date(dateArrayInt[0], dateArrayInt[1] - 1, dateArrayInt[2]);

  if (parseTime && dateArrayInt.length > 3) {
    if (dateArrayInt.length >= 4) {
      result.setHours(dateArrayInt[3]);
    }
    if (dateArrayInt.length >= 5) {
      result.setMinutes(dateArrayInt[4]);
    }
    if (dateArrayInt.length >= 6) {
      result.setSeconds(dateArrayInt[5]);
    }
  }

  return result;
}

/**
 * Convierte un string de una fecha en un tipo Moment
 *
 * @param date string con una fecha en formato DD/MM/YYYY HH:mm:ss a convertir
 * @param parseTime indica si se quiere retornar también la hora con minutos y segundos
 * @returns una fecha de tipo Moment
 */
export function stringToMoment(date: string, parseTime: boolean = false): Moment {
  return moment(stringToDate(date, parseTime));
}
//#endregion

//#region ARRAYS
/* ******************************************************************************************************************** */
/* ** ARRAYS
/* ******************************************************************************************************************** */
/**
 * Convierte un array de strings con fechas a un array de tipo Date indicando el formato de fecha que tienen los strings.
 *
 * @param daysStrings array con la lista de fechas
 * @param dateFormat formato en el que se esperan las fechas
 * @returns array de fechas del tipo Date
 */
export function loadDatesFromArrayString(daysStrings: string[], dateFormat = 'YYYY-MM-DD'): Date[] {
  let result: Date[] = [];

  for (let day of daysStrings) {
    result.push(moment(day, dateFormat).toDate());
  }

  return result;
}

/**
 * Convierte un array de strings con fechas a un array de tipo Date ignorando el formato de fecha en el que están los strings.
 *
 * @param daysStrings array con la lista de fechas
 * @returns array de fechas del tipo Date
 */
export function loadDatesFromArrayStringAnyFormat(daysStrings: string[]): Date[] {
  let result: Date[] = [];

  for (let day of daysStrings) {
    result.push(moment(day).toDate());
  }

  return result;
}

/**
 * Comprueba si dos arrays de fechas son iguales.
 *
 * @param dates1 array con la primera lista de fechas
 * @param dates2 array con la segunda lista de fechas
 * @param precisionFormat precisión de la comparación
 * @returns boolean indicando si los arrays son idénticos o no
 */
export function compareDatesArray(dates1: Date[], dates2: Date[], precisionFormat = 'YYYY-MM-DD'): boolean {
  let result: boolean = false;

  if (dates1.length == dates2.length) {
    let commonDates: Date[] = [];

    for (let day1 of dates1) {
      for (let day2 of dates2) {
        if (compare2Dates(day1, day2, precisionFormat) == 0) {
          commonDates.push(day1);
          break;
        }
      }
    }
    if (dates1.length == commonDates.length) {
      result = true;
    }
  }

  return result;
}
//#endregion

//#region FORMS
/* ******************************************************************************************************************** */
/* ** FORMS
/* ******************************************************************************************************************** */
/**
 * Evalúa si el valor pasado está bien formateado para asignarselo a un campo de un formulario
 *
 * @param value fecha que se va a evaludar
 * @param formControlName nombre del campo del formulario para construir el objeto patch
 * @param dateFormat formato de la fecha que se espera
 * @returns objeto con la fecha y el objeto patch para el formulario
 */
export function checkFormDate(value, formControlName: string, dateFormat: string = 'DD/MM/YYYY') {
  let result = {
    date: value,
    patch: null
  };

  try {
    result.date = moment(value, dateFormat, true).toDate();

    if (moment(result.date, dateFormat).isValid()) {
      result.patch = {
        [formControlName]: result.date,
      }
    }
  } catch {
    result.patch = null;
  }

  if (result.patch == null) {
    result.date = null;
  }

  return result;
}

/**
 * F
 *
 * @param event
 * @returns
 */
export function setDateToForm(event, formGroup) {
  if (event && event.target) {
    const { date, patch } = checkFormDate(event.target.value, event.target.name)

    formGroup.patchValue(patch);
    event.value = date;
  }

  return event;
}
//#endregion
